<template>
    <div class="payment-content container">
        <div class="row steps">
            <div class="col-5 step">
                <div class="step-number"> 1. </div>
                <div class="d-inline-block" style="background: #fff; padding-right: 24px;">
                    {{ $translate('step_locate') }}
                </div>
                <div class="line" />
            </div>
            <div class="col-5 step">
                <div class="step-number active"> 2. </div>
                <div class="d-inline-block" style="background: #fff; padding-right: 24px;">
                    {{ $translate('step_payment') }}
                </div>
                <div class="line" />
            </div>
            <div class="col-2 step">
                <div class="step-number"> 3. </div>
                <div class="d-inline-block">
                    {{ $translate('step_location') }}
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-7 payment-description">
                <div class="receive-of">
                    <h1>{{ $translate('receive_loc_of') }}:</h1>
                    <h2 class="number">
                        +{{ $store.phone_code }} {{ $store.phone_number }}
                    </h2>
                    <h3 v-if="$config('debug')"> {{$payment.info.data.gateway.name}} </h3>
                </div>
                <div class="rate">
                    <span class="rate-text">{{ $translate('rate') }}:</span>
                    <span class="amount">
                        {{$payment.getAmount().toFixed(2)}} <sup>*</sup> {{ $store.currency }}
                    </span>
                </div>

                <div class="payment-text">
                    {{ $translate('payment_pre') }} <span class="green">{{ $translate('payment_pre_safe') }}</span>
                </div>

                <div style="text-align: center; padding: 16px;">
                    <img src="/img/front/payment-logos.png" style="max-width: 90%;" alt="payment logos">
                </div>
                <component :is="gateway"/> 
            </div>
            <div class="col-md-5 payment-location">
                <h1>
                    {{ $translate('avail_loc_for') }}
                    <span style="white-space: nowrap;">
                        +{{ $store.phone_code }} {{ $store.phone_number }}
                    </span>
                    {{ $translate('avail_loc_is') }} <span class="green">{{ $translate('avail_loc_available') }}</span>
                </h1>
                <img src="/img/front/icons/map.png" alt="Location" class="img-fluid">
           </div>
        </div>    
        <payment-description/>
    </div>
</template>

<script>
    import PaymentDescription from '../components/payments/Description';
    import sandbox from '../components/payments/gateways/Sandbox';
    import cardstream from '../components/payments/gateways/Cardstream';
    import transacteuro from '../components/payments/gateways/Transacteuro';
    import konntransacteuro from '../components/payments/gateways/Konntransacteuro';
    import konndummy from '../components/payments/gateways/Konndummy';
    import dalenys from '../components/payments/gateways/Dalenys';
    import payxpert from '../components/payments/gateways/Payxpert';
    import konnpaay from '../components/payments/gateways/Konnpaay';
    import emerchantpay from '../components/payments/gateways/Emerchantpay';
    import konncomputop from '../components/payments/gateways/Konncomputop';
    import konnemerchantpay from '../components/payments/gateways/Konnemerchantpay';

    export default{
        components: {
            PaymentDescription,
            sandbox,
            cardstream,
            transacteuro,
            konntransacteuro,
            konndummy,
            dalenys,
            payxpert,
            konnpaay,
            emerchantpay,
            konncomputop,
            konnemerchantpay
        },
        computed: {
            gateway(){
                return this.$payment.info.data.gateway.key;
            }
        }
    }
</script>
